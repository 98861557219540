import axios from 'axios'

export default {

    getCSRF() {

        var path = '/sanctum/csrf-cookie'

        return axios.get(path);
    },
    login(email, password, basketId) {

        var path = '/api/customer/login'
        var data = {
            email: email,
            password: password,
            basketId: basketId,
            merchantId: 8451006594,
            app: 'customer'
        }

        return axios.post(path, data)
    },
    logout() {

        var path = 'api/customer/logout'

        return axios.get(path)
    },
    getCurrentUser() {

        var path = '/api/customer/user'

        return axios.get(path)
    },
    register(email, password, basketId, firstName, lastName, address, phoneNumber, listingId) {

        var path = '/api/customer/register'
        var data = {
            email: email,
            password: password,
            basketId: basketId,
            lastName: lastName,
            firstName: firstName,
            address: address,
            phoneNumber: phoneNumber,
            listingId: listingId,
            acceptMangoPayTerms: true
        }

        return axios.post(path, data)
    },
    forgotPassword(email) {

        var path = '/api/forgot-password'
        var data = {
            email: email,
            merchantId: 8451006594,
            app: 'customer'
        }

        return axios.post(path, data)
    },
    resetPassword(token, email, password, passwordConfirmation) {

        var path = '/api/reset-password'
        var data = {
            token: token,
            email: email,
            password: password,
            password_confirmation: passwordConfirmation,
            merchantId: 8451006594,
            app: 'customer'
        }

        return axios.post(path, data)
    }
}
