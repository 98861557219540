<script type="text/ecmascript-6">
export default {
    props: ['maxBestOffer', 'merchant', 'bonus'],
    methods: {

        getColor() {

            return this.bonus.color
        },
        getBonusAmount() {

            return this.bonus.bonus_amount / 100
        },
        getBrandName() {

            return this.bonus.name.toUpperCase()
        },
        getPublicId() {

            return this.bonus.public_id
        },
        emitCampaign() {

            this.$emit('setCampaign', { campaign: this.bonus })
        }
    }
}
</script>

<template>
    <div class="d-flex flex-row mt-4 ms-3">
        <div class="card shadow-lg rounded-0 col-7 mx-0">
            <div class="row mx-0 h-100">
                <div class="col-1 px-0 row">
                    <div :class="'bg-' + getColor()" class="col-7 px-0"></div>
                </div>
                <div class="col-1 d-flex justify-content-center align-items-center px-0">
                    <input class="form-check-input" type="radio" :id="'campaign-' + getPublicId()" name="campaign"  v-on:click="emitCampaign()">
                </div>
                <div v-if="maxBestOffer.price > 0" class="card-body pt-3 col-10">
                    <p class="text-center mb-0">{{merchant.coupon_name.toUpperCase()}}<br/><strong>TOUS PRODUITS</strong></p>
                    <h2 :class="'text-' + getColor()" class="justify-content-center d-flex"><strong>{{maxBestOffer.price | currency}}*</strong></h2>
                    <p class="font-size-xxs mb-0 mx-3 text-gd-faint">
                        Valable <strong>{{merchant.coupon_validity_period}} {{merchant.coupon_validity_products}} {{merchant.coupon_validity_places}}</strong>
                    </p>
                </div>
                <div v-else class="card-body pt-3 col-10">
                    <p class="text-center"><strong>Recyclage</strong></p>
                    <p>Nous n'avons pas d'offre sur ce produit.<br/>Expédiez le <strong>gratuitement</strong> et il sera recyclé par notre partenaire.</p>
                </div>
            </div>
        </div>
        <div class="card shadow-lg rounded-0 col-5 px-0 vouch-bonus-separator">
            <div class="mx-2 pt-3">
                <p class="text-center mb-0">BONUS<br/><strong>{{getBrandName()}}</strong></p>
                <h2 :class="'text-' + getColor()" class="justify-content-center d-flex"><strong>+ {{getBonusAmount()}} €</strong></h2>
                <p class="font-size-xxs mb-0 mx-3 text-gd-faint">
                    Sous forme de code de réduction
                    <strong v-html="bonus.legal_application_label"></strong>
                </p>
            </div>
        </div>
    </div>
</template>