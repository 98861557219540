<script type="text/ecmascript-6">

import BasketLayout from '../../layouts/BasketV2.vue'
import orderMixin from '@/mixins/order'
import merchantMixin from '@/mixins/merchant'
import FaqMixin from '@/mixins/faq'
import AcceptedOffer from '../../components/basket/AcceptedOfferV2.vue'
import ProductCard from '../../components/basket/ProductCardV2.vue'
import ServicesCard from '../../components/basket/ServicesCard.vue'
import ProductCaption from '../../components/basket/ProductCaption.vue'
import orderService from '../../services/order'
import SuccessAlert from '@/components/basket/SuccessAlert.vue'
import StandardModal from '@/components/StandardModal.vue'

export default {

    components: {BasketLayout, AcceptedOffer, ProductCard, ServicesCard, ProductCaption, SuccessAlert, StandardModal}, 
    mixins: [orderMixin, merchantMixin, FaqMixin],
    data() {

        return {

            isDownloading: false,
            isLoading: false,
            error: ''
        }
    },
    created() {
      
        if (this.$route.query.l == 1) {

            this.isLoading = true
        }

        this.$store.commit('setCurrentStep', 50)
    },
    methods: {

        async downloadDeliveryNote() {

            this.isDownloading = true
            
            try {

                let newWindow = window.open(window.location.href + '?l=1');
                let response = await orderService.getDeliveryNote(this.order.public_id)
                newWindow.location = response.data.url
                this.$router.push({name: 'orderConfirm', params: {id: this.order.public_id}, query: {f: 1}})
            }
            catch (e) {

                this.error = 'Une erreur est survenue lors de la création de l\'étiquette. Veuillez contacter le support.'
            }
        },
    },
    computed: {

        isTransferSigned() {

            return this.isOrderLoaded && this.order.is_transfer_signed
        },
        isMobile() {
            
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) === true
        },
    },
    watch: {

        isOrderLoaded() {

            this.$tracking.pageView('delivery', 'order', {'Order ID': this.orderId, 'Coupon Type': this.order.coupon_type})
        }
    }
}
</script>
<template>
    <basket-layout>
        <div v-if="isTransferSigned">
            <div class="row mt-3 mt-md-5">
                <div class="col-md-7 ms-md-5">
                    <div class="col-md-11 text-light">
                        <success-alert v-if="$route.query.t == 1" :message="'Votre bon de cession a bien été signé.'" />
                        <h3 class="text-light">Expédition du produit</h3>
                        <p>Il ne vous reste plus qu’à <strong>expédier gratuitement votre produit</strong>.</p>
                        <p>Pour cela, merci de bien utiliser <strong>l’étiquette d’expédition</strong> que vous pouvez <strong>télécharger</strong> grâce au bouton ci-dessous, à coller sur le carton emballant votre produit.</p>
                        <div v-if="error" class="alert alert-danger" role="alert">
                            {{error}}
                        </div>
                        <button class="btn btn-success" :disabled="isDownloading" type="button" v-on:click="downloadDeliveryNote()">
                            <span v-if="!isMobile">Je télécharge l'étiquette d'expédition</span>
                            <span v-if="isMobile">Je reçois par email l'étiquette d'expédition</span>
                            <span v-if="isDownloading" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                        <div class="card my-4">
                            <div class="card-body row pb-2">
                                <div class="col-1 d-flex align-items-center justify-content-center">
                                    <i class="bi bi-question-circle text-success font-size-2rem"></i>
                                </div>
                                <div class="col">
                                    <p class="text-light" ><strong>Comment emballer votre produit ?</strong>
                                    <br/>Retrouvez nos conseils pour bien emballer votre colis en <a :href="getFAQDeliveryURL()" target="_blank">cliquant ici</a>.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="offset-md-0 col-md-4">
                    <div class="card shadow-lg w-100">
                        <product-card :basket="order" />
                        <accepted-offer :price="order.price" :merchant="order.merchant" :type="order.coupon_type" :bonus="order.brand_campaign"/>
                    </div>
                    <services-card v-if="merchant" :productType="order.product_type" :merchant="merchant" />
                    <product-caption/>
                </div>
            </div>
        </div>
        <standard-modal v-if="isLoading" :title="'Veuillez patentier pendant le chargement de votre étiquette de transport'" :closeLabel="'Fermer'" @close="isLoading = false">
            <template v-slot:body>
                <div class="d-flex flex-column justify-content-center">
                    <div class="spinner-border m-auto" style="width: 8rem; height: 8rem;" role="status"></div>
                </div>
            </template>
        </standard-modal>
    </basket-layout>
</template>