<script type="text/ecmascript-6">

import BasketLayout from '../../layouts/BasketV2.vue'
import basketMixin from '../../mixins/basket'
import basketService from '@/services/basket'
import merchantMixin from '@/mixins/merchant'
import ProductCard from '../../components/basket/ProductCardV2.vue'
import MaxBestOffer from '../../components/basket/MaxBestOfferV2.vue'
import ServicesCard from '../../components/basket/ServicesCard.vue'
import ProductCaption from '../../components/basket/ProductCaption.vue'
import Conditions from '../../components/basket/Conditions.vue'
import StandardModal from '@/components/StandardModal.vue'

export default {

    mixins: [basketMixin, merchantMixin],
    components: {BasketLayout, ProductCard, MaxBestOffer, ServicesCard, ProductCaption, Conditions, StandardModal}, 

    data() {

        return {

            isLoading: false,
            isError: false,
            maxBestOffer: null,
            alertCondition: null
        }
    },
    created() {

        this.loadMaxBestOffer(this.basket)
        this.$tracking.pageView('basket', 'basket', {'Basket ID': this.basketId})
        this.setCurrentStep(10)
    },
    watch: {

        basket() {

            this.loadMaxBestOffer(this.basket)
        }
    },
    computed: {

        isMaxBestOfferLoaded() {

            return this.maxBestOffer != null
        },
        productType() {

            return this.basket.product_type;
        },
        productConditions() {

            return this.basket.product_conditions
        },
        basketConditions() {

            return this.basket.conditions != null ? JSON.parse(this.basket.conditions) : undefined
        },
        canValidate() {

            if (this.basketConditions == undefined) return false

            let conditions = Object.values(this.basket.product_conditions)
            for (let i = 0; i < conditions.length; i++) {
                
                let condition = conditions[i]
                if (this.basketConditions[condition.name] == undefined) {

                    return false
                }

                if (condition.answer != undefined) {

                    if (condition.answer != this.basketConditions[condition.name]) {

                        return false
                    }
                }
            }

            return true
        }
    },
    methods: {

        updateCondition(basketCondition, event) {

            let condition = {}
            condition[basketCondition.name] = basketCondition.val
            basketService.updateCondition(this.basket.public_id, condition)
                .then(response => {

                    if (response.data == 1) {

                        this.$store.commit('updateCondition', basketCondition)
                    }
                    else {

                        this.$router.push({name: 'basketError404'})
                    }
                })
                .catch(error => {

                    if (error.response.status == 404) {

                        this.$router.push({name: 'basketError404'})
                    }

                    if (error.response.status == 422) {

                        this.alertCondition = basketCondition
                        event.target.checked = false
                    }
                })
        },
        async loadMaxBestOffer(basket) {

            if (this.basket != undefined && this.maxBestOffer == undefined) {

                let response = await basketService.getMaxBestOffer(basket.public_id)
                let infos = response.data
                this.maxBestOffer = {price: infos.max_best_offer_price, currency: infos.max_best_offer_currency, maxBonus: infos.max_bonus_amount}
                this.$tracking.logEvent('Max Best Offer', {'Best Price': infos.max_best_offer_price, 'Product Type': this.basket.product_type})
            }
        },
        validate() {

            this.$router.push({name: 'basketAcceptOffer', params: {id: this.basket.public_id}})
        }
    }
}
</script>
<style scoped>
.subtitle {
    font-size: 0.6em;
    font-weight: normal;
}
</style>
<template>
    <basket-layout>
        <div v-if="this.basket">
            <div class="row mt-3 mt-md-5">
                <div class="col-md-7 ms-md-5">
                    <div class="col-md-11">
                        <div>
                            <h3 class="text-light">Revendez votre {{$t('CATEGORY.' + this.basket.product_type)}}
                            <br/><small class="subtitle text-light">simplement, immédiatement et au meilleur prix</small></h3>
                        </div>
                        <div class="card shadow-lg mt-4">
                            <div class="card-body">
                                <conditions :conditions="productConditions" :productConditions="basketConditions" :productType="basket.product_type" @updateCondition="updateCondition"/>
                            </div>
                        </div>
                        <button :disabled="!canValidate" class="btn btn-success shadow-lg text-white w-100 text-center mt-3" @click="validate">VOIR MON OFFRE</button>
                        <span v-if="!canValidate" class="d-flex justify-content-center mt-2 text-light small">Finalisez votre déclaratif afin d'obtenir votre offre</span>
                        <div class="card border-0 my-4">
                            <div class="card-body text-light">
                                <h6 class="text-light">IMPORTANT</h6>
                                <p>
                                    L'état de votre produit est vérifié à son arrivée.<br>
                                    Si les informations fournies sont inexactes, votre produit pourra être refusé ou soumis à une contre-offre de reprise.<br>
                                    En cas de retour, <span class="text-danger"><strong>les frais de livraison vous seront facturés.</strong></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="offset-md-0 col-md-4">
                    <div class="card shadow-lg w-100">
                        <product-card :basket="basket" />
                        <max-best-offer v-if="maxBestOffer != undefined" :maxBestOffer="maxBestOffer" :merchant="merchant" :maxBonus="maxBestOffer.maxBonus"/>
                    </div>
                    <services-card v-if="merchant" :productType="basket.product_type" :merchant="merchant" />
                    <product-caption/>
                </div>
            </div>
        </div>
        <standard-modal v-if="alertCondition" :title="'Une erreur est survenue'" :closeClass="'btn-success'" :closeLabel="'Fermer'" @close="alertCondition = null">
            <template v-slot:body>
                 <p v-html="$t('BASKET_CONDITION.' + alertCondition.name + '.' + basket.product_type + '.ALERT')"></p>
            </template>
        </standard-modal>
    </basket-layout>
</template>
