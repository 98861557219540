<script type="text/ecmascript-6">
    import BasketHeader from '../components/basket/HeaderV2.vue'
    import BasketFooter from '../components/basket/FooterV2.vue'
    import BasketReinsurance from '../components/ReinsuranceV2.vue'

    export default {
        components: {BasketHeader, BasketFooter, BasketReinsurance},
        props: {
            withBreadCrumbs: {

                type: Boolean,
                default: true
            }
        },
        data() {

            return {

                metaRobotsContent: process.env.VUE_APP_ENVIRONMENT === 'production' ? 'all' : 'none'
            }
        },
        metaInfo() {
            const metaRobotsContent = this.metaRobotsContent
            return {
                meta: [
                    {name: 'robots', content: metaRobotsContent}
                ]
            }
        }
    }
</script>
<template>
    <div class="basket-bg-color">
        <basket-header :withBreadCrumbs="withBreadCrumbs" />
        <div class="container">
            <slot/>
        </div>
        <basket-reinsurance />
        <basket-footer />
    </div>
</template>

<style scoped>

    .basket-bg-color {

        background-color: #F2F5FC;
    }

</style>
