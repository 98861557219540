<script type="text/ecmascript-6">

import HomeLayout from '@/layouts/Home.vue'
    
    export default {

        components: {HomeLayout},
        data() {

            return {

                categories:[ 

                    {name:'Smartphones', id:3},
                    {name:'Consoles de jeux', id:7},
                    {name:'Tablettes', id:6},
                    {name:'Ordinateurs portables', id:2},
                    {name:'PC fixes', id:9},
                    {name:'Montres connectés', id:14},
                    // {name:'Autres', id:15},
                ],
            }
        },
        methods: {

            goToForm(id) {

                if (id === '') {

                    this.$tracking.logEvent("Click Go To Search Form", {'Page Name': 'Home', 'Placeholder':'Resell Button'})
                    window.location = process.env.VUE_APP_RESALE_DOMAIN + '/searchform'
                }
                else {

                    this.$tracking.logEvent('Click Go To Search Form', {'Category ID': id, 'Page Name': 'Home', 'Placeholder':'Mosaic'})
                    window.location = process.env.VUE_APP_RESALE_DOMAIN + '/searchform?cid=' + id
                }
            },
        },
        created() {

            this.$tracking.pageView('Home', 'Home')
        },
        metaInfo: {

            title: 'Revendez vos produits en 2 minutes | Happydeal Online'
        }
    }
</script>

<template>

    <HomeLayout class="bg-white margin-under-navbar">
        <main class="d-flex flex-column h-100">
            <div class="d-flex flex-column justify-content-center flex-md-row mt-4 mt-md-2">
                <div class="d-flex flex-column justify-content-between pb-3 pb-md-0 col-md-7 col-lg-7 mt-lg-0">
                    <div class="row d-flex justify-content-center d-md-block">
                        <div class="d-flex flex-column align-items-center d-md-block col-11 text-center text-md-start">
                            <!-- <h1></h1> -->
                            <h1 class="text-light fw-bold d-xl-block d-none">Vendez ici en <span class="text-success fw-bold">2 minutes<br class="d-none d-md-block">
                            les produits</span>  que vous n'utilisez plus !</h1>


                            <h1 style="font-size:2rem" class="text-light fw-bold d-xl-none d-block">Vendez ici en <span class="text-success fw-bold">2 minutes<br class="d-none d-md-block">
                            les produits</span>  que vous n'utilisez plus !</h1>
                            <h5 class="text-light fw-bold mt-2">Nous rachetons vos produits en ligne et au meilleur prix <span class="text-success fw-bold">contre du cash !</span></h5>
                            
                            <button  @click="goToForm('')" class="btn btn-success shadow-button my-3 mx-2 me-0 me-md-5 py-2 col-4 col-lg-4">
                                <span class="fw-bold fs-lg">JE VENDS</span>
                            </button>
                        </div>
                    </div>
                    <div class="row mb-3 my-3 my-md-0 mb-md-0 d-none d-md-block">
                        <img class="img col-md-6 " src="/assets/img/happydeal/on ne peut pas faire plus simple aligné gauche.png" alt="On ne peut pas faire plus simple">
                        <img class="img col-md-11" src="/assets/img/happydeal/4 pictos alignés.png" alt="Vendez vos anciens appareils chez nous, renseignez leurs caractéristiques, Envoi sécurisé, Virement immédiat sur votre compte">
                    </div>
                    <div class="row mb-3 my-3 my-md-0 mb-md-0 d-flex flex-column align-items-center d-md-none">
                        <img class="img col-10 col-md-10 " src="/assets/img/happydeal/on ne peut pas faire plus simple centré.png" alt="On ne peut pas faire plus simple">
                        <div class="row d-flex justify-content-center">
                            <img class="col-11 col-sm-8" src="/assets/img/happydeal/etapes 1 & 2.png" alt="Vendez vos anciens appareils chez nous, renseignez leurs caractéristiques">
                            <img class="col-11 col-sm-8" src="/assets/img/happydeal/etapes 3 & 4.png" alt="Envoi sécurisé, Virement immédiat sur votre compte">
                        </div>
                    </div>
                </div>
                <div class="d-none d-md-block col-md-4 col-lg-3">
                    <div class="row mb-4 mb-md-2">
                        <div class="col-6 nopadding">
                            <img @click="goToForm(2)" class="mosaic-button" src="/assets/img/happydeal/macbook.png" alt="Vendre votre ordinateur portable d'occasion au meilleur prix">
                            <img @click="goToForm(14)" class="mosaic-button mt-4 mt-md-2" src="/assets/img/happydeal/montre.png" alt="Vendre votre montre connecté d'occasion au meilleur prix">
                        </div>
                        <div @click="goToForm(3)" class="col-6 ps-4 ps-md-2 nopadding">
                            <img class="mosaic-button" src="/assets/img/happydeal/iphone.png" alt="Vendre votre smartphone ou téléphone d'occasion au meilleur prix">
                        </div>
                    </div>
                    <div class="row my-3 my-md-0">
                        <div @click="goToForm(7)" class="col-12 nopadding">
                            <img class="mosaic-button" src="/assets/img/happydeal/ps4.png" alt="Vendre votre console de jeu d'occasion au meilleur prix">
                        </div>
                    </div>
                </div>            
            </div>
            <div class="row mt-2 mb-3 mt-md-5 d-flex flex-column align-items-center px-2">
                <div class="col-sm-11 col-md-10">
                    <h4 class="my-3 text-light"><strong>Vous n'êtes plus intéressé par <span class="text-success"> vos anciens appareils High-tech ? </span> <br>Nous vous les reprenons ! </strong></h4>
                    <p style="text-align:justify" class="text-light text-justify">Revendre son téléphone et ses appareils High-tech ? Oui, mais pas n'importe comment !</p>
                    <p style="text-align:justify" class="text-light text-justify">Chez Happy Deal Online, plus besoin de se déplacer en magasin ou d'organiser des rendez-vous sur les 
                    sites de petites annonces. Nous reprenons vos produits d'occasion high-tech en ligne grâce à notre 
                    <strong>plateforme sécurisée</strong> et nous vous payons immédiatement par virement bancaire. Que votre produit 
                    électronique soit récent ou d'occasion, nous nous engageons à le reprendre au <strong>prix de reprise le plus 
                    juste</strong>. Vendez en toute confiance vos <strong>téléphones portables</strong>, vos <strong>tablettes</strong> ou bien même vos <strong>consoles de 
                    jeux vidéo</strong> avec notre processus de reprise <strong>facile, rapide et sécurisant</strong>.</p>
                </div>
            </div>
        </main>
    </HomeLayout>
</template>

<style scoped>

@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@100;200;300;400;500;600;700;800;900&display=swap');
* {
    
    font-family: 'Catamaran', sans-serif;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    
}
.nopadding {
   padding: 0;
}

.shadow-button {

    box-shadow: -6px 6px 0px 0px #0d2c58;
    border-radius:8px;
}

.mosaic-button:hover, .shadow-button:hover {

    cursor:pointer;
    filter: brightness(0.8);
    transition: 0.3s;
}

@media screen and (max-width: 500px) {
    .margin-under-navbar {

        margin-top:27%;
    }   
}

@media screen and (min-width: 500px) {
    .margin-under-navbar {

        margin-top:15%;
    }   
}

@media screen and (min-width: 768px) {
    .margin-under-navbar {

        margin-top:9%;
    }   
}

</style>
