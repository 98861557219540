import Vue from 'vue'
import Vuex from 'vuex'
import basketModule from '@/store/modules/basket'
import authModule from '@/store/modules/auth'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        
    },
    getters: {
        
    },
    mutations: {
        
    },
    actions: {
        
    },
    modules: {
        basket: basketModule,
        auth: authModule
    }
})
