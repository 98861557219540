<script type="text/ecmascript-6">
export default {
    data() {

        return {

            steps : [
                {
                    step: 10,
                    number: 1,
                    name: 'Déclaratif'
                },
                {
                    step: 20,
                    number: 2,
                    name: 'Offre'
                },
                {
                    step: 40,
                    number: 3,
                    name: 'Confirmation'
                },
                {
                    step: 50,
                    number: 4,
                    name: 'Expédition gratuite'
                },
            ]
        } 
    },
    props: {
        currentStep: Number
    },
    methods: {
        isCurrent(step) {

            if ((step == this.currentStep || (this.currentStep == 10 && step == 20) || (this.currentStep == 20 && step == 10)) && this.currentStep > step) 

                return true

            return false    
        },
        isActive(step) {

            if (step <= this.currentStep)
                return true

            return false
        },
        getColor(step) {

            if (this.isCurrent(step)) {

                return '#21d5b6'
            }
            
            return 'white'
        }
    }
}
</script>
<template>
    <div class="steps steps-light">
        <a class="step-item" v-for="step in steps" :key="step.step" :class="{active: isActive(step.step), current: isCurrent(step.step)}">
            <div class="step-progress">
            <span class="step-count">{{step.number}}</span>
            </div> 
            <div class="step-label" :class="{'text-muted': !isActive(step.step) && !isCurrent(step.step), 'text-success': isCurrent(step.step) || !isCurrent(step.step)}">
                {{step.name}}
            </div>
        </a>
    </div>
</template>
