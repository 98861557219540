<script type="text/ecmascript-6">
export default {
    
}
</script>
<template>
    <div class="row mt-3 mt-md-3 text-success text-md-start text-center home-bg-reinsurance">
        <div class="offset-md-0 col-md-12 mt-md-4 mb-md-2">
            <div class="row d-flex">
                <div class="mt-4 mt-md-0 col-6 col-md-3 d-md-flex justify-content-center px-0">
                    <img class="mb-2 me-md-2" style="width:50px;height:50px" src="/assets/img/happydeal/icons8-fantaisie-100-hc.png" /> 
                    <p class="small mt-md-0 text-light">
                        <strong>Revente en 2 minutes</strong>
                        <span class="mt-1 text-muted"><br>Pas d'annonce à créer<br>ni de prix à négocier</span>
                    </p>
                </div>
                <div class="mt-4 mt-md-0 col-6 col-md-3 d-md-flex justify-content-center px-0">
                    <img class="mb-2 me-md-2" style="width:50px;height:50px" src="/assets/img/happydeal/icons8-sac-d'argent-euro-100-hc.png" />
                    
                    <p class="small mt-md-0 text-light">
                        <strong>Reprise en cash</strong>
                        <span class="mt-1 text-muted"><br>Sous forme<br>de virement bancaire</span>
                    </p>
                </div>
                <div class="mt-2 mt-md-0 col-6 col-md-3 d-md-flex justify-content-center px-0">
                    <img class="mb-2 me-md-2" style="width:50px;height:50px" src="/assets/img/happydeal/icons8-avion-en-papier-100-hc.png" />
                    
                    <p class="small mt-md-0 text-light">
                        <strong>Frais d'expéditions offerts</strong>
                        <span class="mt-1 text-muted"><br>Etiquette pour l’expédition<br>de votre produit fournie</span>
                    </p>
                </div>
                <div class="mt-2 mt-md-0 col-6 col-md-3 d-md-flex justify-content-center px-0">
                    <img class="mb-2 mt-md-0 mb-md-0 me-md-2" style="width:50px;height:50px" src="/assets/img/happydeal/icons8-gourou-100-hc.png" />
                    <p class="small mt-2 mt-md-0 text-light">
                        <strong>Sérénité garantie</strong>
                        <span class="mt-1 text-muted"><br>Accompagnement du début<br>à la fin de la revente</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<style>

    .home-bg-reinsurance {

        background-color: #F2F5FC;
        max-width: 99vw;
    }

</style>