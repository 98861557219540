<script type="text/ecmascript-6">

import BasketLayout from '../../layouts/BasketV2.vue'
import orderMixin from '@/mixins/order'
import merchantMixin from '@/mixins/merchant'
import FaqMixin from '@/mixins/faq'
import AcceptedOffer from '../../components/basket/AcceptedOfferV2.vue'
import ProductCard from '../../components/basket/ProductCardV2.vue'
import ServicesCard from '../../components/basket/ServicesCard.vue'
import ProductCaption from '../../components/basket/ProductCaption.vue'

export default {

    mixins: [orderMixin, merchantMixin, FaqMixin],
    components: {BasketLayout, AcceptedOffer, ProductCard, ServicesCard, ProductCaption}, 
    data() {

        return {

            isIbanAlreadyStocked: localStorage.isAlreadyStocked,
        }
    },
    created() {
      
        this.$store.commit('setCurrentStep', 50)
    },
    methods: {

        toIbanForm() {
            
            this.$tracking.logEvent("Click Go To IBAN Form", {'Page Name': 'Confirm', 'Placeholder':'IBAN Button'})
            this.$router.push({name:'ibanForm'}, {params: {id: this.order.public_id}})
        },
    },
    watch: {

        isOrderLoaded() {

            this.$tracking.pageView('confirm', 'order', {'Order ID': this.orderId, 'Coupon Type': this.order.coupon_type})
        }
    }
}
</script>
<template>
    <basket-layout>
        <div v-if="isOrderLoaded">
            <div class="row mt-3 mt-md-5">
                <div class="col-md-7 ms-md-5">
                    <div class="col-md-11 mb-4 text-light">
                        <h3 class="text-light">Félicitations !<br/>Votre revente est prête.</h3>
                        <p>Merci <strong>d'expédier votre produit sous 7 jours</strong><br>Passé ce délai votre produit pourra être refusé.</p>
                        <p>Vous pourrez suivre l'évolution de votre vente directement depuis votre espace client <strong>HAPPYDEAL</strong>.</p>
                        <p>Votre virement sera effectué sous 14 jours après validation de votre produit et <strong> <span v-if="isIbanAlreadyStocked">validation</span> <span v-else>renseignement</span> de votre IBAN.</strong></p>
                        <button @click="toIbanForm()" class="btn btn-success ms-0 me-3 my-sm-4" type="button" :disabled="isIbanAlreadyStocked">
                            <span v-if="isIbanAlreadyStocked">Votre IBAN est en cours de vérification</span>
                            <span v-else>Renseigner mon IBAN</span>
                        </button>
                        <router-link class="btn btn-light hover-brighter" type="button" :to="{name:'dashboardOrders'}"><span class="text-white">Mon espace client HAPPYDEAL </span></router-link>
                        <div class="card mt-4">
                            <div class="card-body row pb-2">
                                <div class="col-1">
                                    <i class="bi bi-question-circle text-success font-size-2rem"></i>
                                </div>
                                <div class="col">
                                    <p>Si vous avez une question, vous pouvez :</p>
                                    <ul>
                                        <li>consulter notre <a :href="getFAQURL()" target="_blank">FAQ</a></li>
                                        <li>nous contacter par tchat (accessible en bas à droite)</li>
                                        <li>envoyer un email à <a href="mailto:contact@happydealonline.com">contact@happydealonline.com</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="offset-md-0 col-md-4">
                    <div class="card shadow-lg w-100">
                        <product-card :basket="order" />
                        <accepted-offer :price="order.price" :merchant="order.merchant" :type="order.coupon_type" :bonus="order.brand_campaign"/>
                    </div>
                    <services-card v-if="merchant" :productType="order.product_type" :merchant="merchant" />
                    <product-caption/>
                </div>
            </div>
        </div>
    </basket-layout>
</template>

<style scoped>

.hover-brighter:hover {

    background-color:rgba(13,44,88,0.800)
}

</style>