<script type="text/ecmascript-6">
    import HomeHeader from '../components/home/Header.vue'
    import HomeFooter from '../components/home/Footer.vue'
    import HomeReinsurance from '../components/home/Reinsurance.vue'

    export default {

        components: {HomeHeader, HomeFooter, HomeReinsurance},

        data() {

            return {

                categories:[ 

                    {name:'Smartphones', id:3},
                    {name:'Consoles de jeux', id:7},
                    {name:'Tablettes', id:6},
                    {name:'Ordinateurs portables', id:2},
                    {name:'PC fixes', id:9},
                    {name:'Montres connectés', id:14},
                    // {name:'Autres', id:15},
                ],
                burgerToggled: false,
                metaRobotsContent: process.env.VUE_APP_ENVIRONMENT === 'production' ? 'all' : 'none'
            }
        },
        methods: {

            closeBurgerMenu() {

                if (this.burgerToggled) {

                    this.burgerToggled = false
                }
            },
        },
        metaInfo() {
            const metaRobotsContent = this.metaRobotsContent
            return {
                meta: [
                    {name: 'robots', content: metaRobotsContent}
                ]
            }
        }
    }
</script>
<template>
    <div @click="closeBurgerMenu">
        <home-header @burgerToggled="burgerToggled = !burgerToggled" :burgerToggled="burgerToggled" :categories="categories"/>  
        <div class="d-flex align-items-center">
            <slot/>
        </div>      
        <home-reinsurance />
        <home-footer />
    </div>
</template>
