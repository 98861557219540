import orderService from '@/services/order'
import merchantMixin from '@/mixins/merchant'
import * as Sentry from "@sentry/vue";

export default {

    mixins: {merchantMixin},
    data() {
        return {
            order: null
        }
    },
    created() {
      
        let withCanBeImmediat = this.withCanBeImmediat && this.withCanBeImmediat === true ? true : false
        this.loadOrder(this.$route.params.id, withCanBeImmediat)
    },
    methods: {

        async loadOrder(orderPublicId, withCanBeImmediat = false) {

            try {

                let response = await orderService.get(orderPublicId, withCanBeImmediat);
                this.order = response.data
                await this.loadMerchant(this.order.merchant_id)
            }
            catch (error) {

                Sentry.captureException(error)
                window.location = process.env.VUE_APP_MAIN_DOMAIN
            }
        },
    },
    computed: {

        isOrderLoaded() {

            return this.order != null
        },
        orderId() {

            return this.$route.params.id
        }
    },
    watch: {

        isOrderLoaded() {

            let routeName = ''
            if (this.order.order_status_id == 9) {

                if (this.order.refused_counter_offer != undefined)
                    routeName = 'orderCounterOfferRefused'
                else
                    routeName = 'orderRefused'
            }
            else if (this.order.order_status_id == 1) {

                if (this.order.is_invoice_needed && (this.order.user.active_identity_proofs_count > 0 || this.$route.query.f == 1))
                    routeName = 'orderInvoice'
                else if (this.order.user.active_identity_proofs_count > 0 || this.$route.query.f == 1 || this.$route.query.t == 1)
                    routeName = 'orderTransfer'
                else 
                    routeName = 'orderKyc'
            }
            else if (this.order.order_status_id == 2 && this.$route.query.k == 1) {

                routeName = 'orderKyc'
            }
            else if (this.order.order_status_id == 2 && this.$route.query.f != 1) {

                routeName = 'orderDelivery'
            }
            else if (this.order.order_status_id == 3) {

                routeName = 'orderSent'
            }
            else if (this.order.order_status_id == 4) {

                routeName = 'orderToValidate'
            }
            else if (this.order.order_status_id == 5) {

                if (this.order.accepted_counter_offer != undefined)
                    routeName = 'orderCounterOfferAccepted'
                else
                    routeName = 'orderAccepted'
            }
            else if (this.order.order_status_id == 6) {

                routeName = 'orderCounterOffer'
            }
            else if (this.order.order_status_id == 20) {

                routeName = 'orderExpired'
            }
            else if (this.order.order_status_id == 7) {

                routeName = 'orderStandBy'
            }
            else if (this.order.order_status_id == 2) {

                routeName = 'orderConfirm'
            }
 
            if (routeName != '' && routeName != this.$route.name) {

                this.$router.push({name: routeName, query: this.$route.query})
            }
        }
    }
}