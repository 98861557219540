<script type="text/ecmascript-6">

export default {

    data() {

        return {


        }
    },
    computed: {

        domainName() {

            return process.env.VUE_APP_MAIN_DOMAIN;
        }
    }, 
}

</script>

<template>
   <div class="container">
        <div class="row mt-4">
            <div class="offset-md-4 col-md-4 col text-center">
                <a :href="domainName + '/'">
                    <img src="/assets/img/happydeal/logo happy deal.png" width="180" />
                </a>
            </div>
        </div>
        <div class="row mt-5">
            <div class="offset-md-4 col-md-4 col">
                <slot/>
            </div>
        </div>
    </div>
</template>
