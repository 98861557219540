import * as Sentry from "@sentry/vue"

export default {

    install (Vue) {
    
        Sentry.init({
            Vue: Vue,
            dsn: process.env.VUE_APP_SENTRY_DSN,
            environment: process.env.VUE_APP_ENVIRONMENT,
            beforeSend(event) {
            
                return process.env.VUE_APP_ENVIRONMENT == 'local' ? null : event    
            },
        })
    }
}
