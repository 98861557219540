<script type="text/ecmascript-6">

export default {
    props:['burgerToggled', 'categories'],
    data(){

        return {

            headerTabs: this.categories,
            burgerToggledProps: this.burgerToggled
        }
    },
    methods: {

        track(id) {

            this.$tracking.logEvent('Click Go To Search Form', {'Category ID': id, 'Placeholder':'Header', 'Page Name': 'Home'})
        },

        toggleBurger() {

            this.burgerToggledProps = !this.burgerToggledProps
            
            this.$emit('burgerToggled', this.burgerToggledProps)
        },
        trackAccountButton() {

            this.$tracking.logEvent('Click Go To Account', {'Page Name': 'Home', 'Placeholder':'Account Button'})
        }
    },
    computed: {

        domainName() {

            return process.env.VUE_APP_RESALE_DOMAIN;
        }
    } 
}
</script>
<template>
    <div style="top:0" class="position-fixed d-flex justify-content-between flex-lg-row flex-row shadow align-items-md-center align-items-center p-3 bg-white shadow px-lg-5 w-100">
        <a class="col-sm-4 col-md-2  mb-0" href="/">
            <img src="/assets/img/happydeal/logo happy deal.png" />
        </a>
        <div style="width:100%" class="d-md-flex flex-row align-items-center justify-content-around d-none">
            <a :href="domainName + '/searchForm?cid=' + tab.id" @click="track(tab.id)" id="link" v-for="tab in headerTabs" :key="tab.id" class="d-flex flex-column justify-content-center align-items-center"> 
                <!-- changement de font-size responsive -->
                <span class="mb-0 text-success fw-bold fs-ms  d-md-block d-lg-none">Vendre</span> 
                <span class="mb-0 text-light fw-bold fs-ms d-md-block d-lg-none">{{ tab.name }}</span>

                <span class="mb-0 text-success fw-bold fs-md d-lg-block d-md-none">Vendre</span>
                <span class="mb-0 text-light fw-bold fs-md d-lg-block  d-md-none">{{ tab.name }}</span>
                <!-- changement de font-size responsive -->
            </a>
            <a :href="domainName + '/dashboard/orders'" @click="trackAccountButton()" class="btn btn-light p-2 account-button"><span class="fw-bold text-white">Mon compte</span></a>
        </div>
        <div class="d-flex flex-column">
            <div class="d-flex">
                <button class="btn btn-success d-flex align-items-center justify-content-between d-md-none border-none px-3 py-2 mx-3 shadow-sm" @click.stop="toggleBurger()"><span class="fw-bold fs-lg">Vendre</span></button>
                <a :href="domainName + '/dashboard/orders'" @click="trackAccountButton()" class="btn btn-light p-2 account-button d-md-none"><span class="fw-bold fs-lg text-white">Mon compte</span></a>
            </div>
            
            <div style="right:0%; top:93%" class="position-absolute mt-2 col-4 shadow-lg" v-if="burgerToggled">
                <ul class="navbar-nav bg-gd-dark">
                    <li id="link" v-for="tab in headerTabs" :key="tab.id" class="nav-item">
                        <a :href="domainName + '/searchForm?cid=' + tab.id" @click="track(tab.id)" class="nav-link text-light">{{ tab.name }}</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<style scoped>

@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
    
    font-family: 'Catamaran', sans-serif;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

#link:hover {

    cursor:pointer;
}

.account-button:hover {

cursor:pointer;
filter: brightness(1.2);
transition: 0.3s;
}

</style>
