<script type="text/ecmascript-6">
export default {
    props: ['productType', 'merchant'],
    methods: {

        getCO2Data() {

            switch(this.productType) {
                case 'CONSOLE':
                    return '69'
                case 'GRAPHIC_CARD':
                    return '9'
                case 'LAPTOP':
                    return '124'
                case 'SMARTPHONE_APPLE':
                    return '33'
                case 'SMARTPHONE_OTHER':
                    return '33'
                case 'TABLET_APPLE':
                    return '47'
                case 'TABLET_OTHER':
                    return '47'
                case 'OTHER':
                    return 'Des '
                default:
                    return 'Des '
            }
        },
    }
}

</script>

<template>
    <div class="card shadow-lg mt-2 w-100">
        <div class="card-body pe-3 text-light">
            <div class="row">
                <div class="col-6">
                    <img src="/assets/img/send-check.svg"/><span class="ms-1 font-size-xxs"> Frais d'expédition <strong>offerts</strong></span>
                </div>
                <div class="col-6">
                    <i class="bi bi-cash text-success"/><span class="ms-1 font-size-xxs"> Reprise en <strong>cash</strong><sup>(1)</sup></span>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-6">
                    <i class="bi bi-shield-shaded text-success"/><span class="ms-1 font-size-xxs"> Reprise par un <strong>professionnel</strong></span>
                </div>
                <div v-if="getCO2Data() != ''" class="col-6">
                    <img src="/assets/img/icons8-planète-terre.svg" width="20" height="19"/><span class="ms-1 font-size-xxs"> {{this.getCO2Data()}}kg de CO2 <strong>économisés</strong></span>
                </div>
            </div>
        </div>
    </div>
</template>

