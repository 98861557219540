<script type="text/ecmascript-6">

import BasketLayout from '../../layouts/BasketV2.vue'
import orderMixin from '../../mixins/order'
import merchantMixin from '@/mixins/merchant'
import CounteredOffer from '../../components/basket/CounteredOffer.vue'
import orderService from '../../services/order'
import StandardModal from '../../components/StandardModalV2.vue'
import ProductCard from '../../components/basket/ProductCardV2.vue'
import ServicesCard from '../../components/basket/ServicesCard.vue'
import ProductCaption from '../../components/basket/ProductCaption.vue'

export default {

    components: {BasketLayout, CounteredOffer, StandardModal, ProductCard, ServicesCard, ProductCaption},
    mixins: [orderMixin, merchantMixin],
    data() {

        return {
            isAcceptLoading: false,
            isRefuseLoading: false,
            tryToRefuse: false,
            tryToAccept: false
        }
    },
    created() {
      
        this.$tracking.pageView('counter offer', 'order', {'Order ID': this.orderId})
        this.$store.commit('setCurrentStep', 50)
    },
    methods: {
        async accept() {

            this.isAcceptLoading = true
            try {

                await orderService.acceptCounterOffer(this.order.public_id)  
                this.$router.push({name: 'orderTransfer'}) 
            }
            catch (e) {

                this.$router.go()
            }

            this.isAcceptLoading = false
        },
        async refuse() {

            try {

                await orderService.refuseCounterOffer(this.order.public_id)
                this.$router.push({name: 'orderCounterOfferRefused'})
            }
            catch (e) {

                this.$router.go()
            }
        }
    }
}
</script>
<style scoped>
    .btn-outline-danger {
        background-color: white !important;
    }
    .btn-outline-danger:hover {
        background-color: #f34770 !important;
    }
</style>
<template>
    <basket-layout :withBreadCrumbs="false">
        <div v-if="isOrderLoaded">
            <div class="row mt-3 mt-md-5">
                <div class="col-md-7 ms-md-5">
                    <div class="col-md-11 text-light">
                        <h3 class="text-light">Contre-Offre</h3>
                        <p class="mt-4">Suite au diagnostic de votre produit, notre partenaire {{order.reseller.name}} indique que <strong>son état n'est pas conforme à votre déclaratif.</strong></p>
                        <p>Voici la raison :<br/>« {{order.active_counter_offer.reason}} »<br/><i>Technicien de l'atelier de {{order.reseller.name}}</i></p>
                        <p class="mb-0">Nous vous proposons par conséquent de revoir le prix de reprise de votre produit à</p>
                        <h5><strong class="text-success">{{order.price | currency}}.</strong></h5>
                        <div class="row d-flex justify-content-center my-5 px-3">
                            <button :disabled="isAcceptLoading || isRefuseLoading" role="button" class="btn btn-success col-7" @click="accept()">
                                <span v-if="isAcceptLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                                J'accepte la contre-offre <br class="d-md-none">de {{order.price | currency}}
                            </button>
                            <button :disabled="isAcceptLoading || isRefuseLoading" role="button" class="btn btn-danger offset-1 col-4" @click="tryToRefuse = true">
                                <span v-if="isRefuseLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                                Je refuse
                            </button>
                        </div>
                    </div>
                </div>
                <div class="offset-md-0 col-md-4">
                    <div class="card shadow-lg w-100">
                        <product-card :basket="order" />
                        <countered-offer :counter="order.active_counter_offer" />
                    </div>
                    <services-card v-if="merchant" :productType="order.product_type" :merchant="merchant" />
                    <product-caption/>
                </div>
            </div>
        </div>
        <standard-modal v-if="tryToRefuse" :closeLabel="'Annuler'" @close="tryToRefuse = false">
            <template v-slot:body>
                <div v-if="order.coupon_type == 'POSTPONED'">
                    <div class="text-light">
                        <p>Etes vous sûr de vouloir refuser la contre offre de <strong>{{order.active_counter_offer.to_price | currencyInteger}}</strong> ?</p>
                        <p>En cas de refus, afin de récupérer votre produit, vous devrez payer <strong>10 €</strong> pour les frais de retour.</p>
                    </div>
                    <div class="row d-flex justify-content-center mt-4">
                        <button role="button" class="btn btn-success col-5" @click="refuse">Je refuse la <br class="d-md-none">contre-offre</button>
                        <button role="button" class="btn btn-outline-danger offset-1 col-5" @click="tryToRefuse = false">J'ai changé <br class="d-md-none">d'avis</button>
                    </div>
                </div>
            </template>
        </standard-modal>
    </basket-layout>
</template>