<script type="text/ecmascript-6">
export default {
    props: ['price', 'merchant', 'type', 'bonus'],
    methods: {
        getBrandName() {

            return this.bonus.name.toUpperCase()
        },
        getBonusAmount() {

            let value = this.bonus.bonus_amount / 100
            value = this.$options.filters.currency(value.toString())
            return value
        }
    }
}
</script>
<template>
    <div class="card-body pb-0">
        <hr class="card-separator"/>
        <div class="row mt-4">
            <div class="col-8">
                <span>
                    En <strong>CASH</strong>
                </span>
            </div>
            <div class="col-4 justify-content-end d-flex flex-wrap align-items-center">
                <h4 class="text-success">{{price | currency}}*</h4>
            </div>
        </div>
    </div>
</template>
