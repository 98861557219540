<script type="text/ecmascript-6">

import BasketLayout from '@/layouts/BasketV2.vue'
import orderMixin from '@/mixins/order'
import merchantMixin from '@/mixins/merchant'
import AcceptedOffer from '@/components/basket/AcceptedOfferV2.vue'
import ProductCard from '@/components/basket/ProductCardV2.vue'
import ServicesCard from '@/components/basket/ServicesCard.vue'
import ProductCaption from '@/components/basket/ProductCaption.vue'

export default {

    components: {BasketLayout, AcceptedOffer, ProductCard, ServicesCard, ProductCaption}, 
    mixins: [orderMixin, merchantMixin],
    created() {
      
        this.$tracking.pageView('to validate', 'order', {'Order ID': this.orderId})
    },
}
</script>
<template>
    <basket-layout :withBreadCrumbs="false">
        <div v-if="isOrderLoaded">
            <div class="row mt-3 mt-md-5">
                <div class="col-md-7 ms-md-5">
                    <div class="col-md-11 text-light">
                        <h3 class="text-light">Votre produit a bien été réceptionné par notre partenaire.</h3>
                        <p>
                            Il est actuellement en cours de diagnostic pour vérifier la conformité de son état avec votre déclaratif.
                            <br/>Vous recevrez un mail vous confirmant la reprise de votre produit d’ici 3 jours au plus tard *.
                        </p>
                        <a class="btn btn-success" type="button" :href="order.merchant.orders_history_url">Mon espace client {{order.merchant.name}}</a>
                        <p class="mb-4 text-light"><small><em class="text-muted">* Sous réserve de conformité avec le déclaratif.</em></small></p>
                    </div>
                </div>
                <div class="offset-md-0 col-md-4">
                    <div class="card shadow-lg w-100">
                        <product-card :basket="order" />
                        <accepted-offer :price="order.price" :merchant="order.merchant" :type="order.coupon_type" :bonus="order.brand_campaign"/>
                    </div>
                    <services-card v-if="merchant" :productType="order.product_type" :merchant="merchant" />
                    <product-caption/>
                </div>
            </div>
        </div>
    </basket-layout>
</template>