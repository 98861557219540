<script type="text/ecmascript-6">

import BasketLayout from '@/layouts/BasketV2.vue'
import orderMixin from '@/mixins/order'
import merchantMixin from '@/mixins/merchant'
import AcceptedOffer from '@/components/basket/CounteredOffer.vue'
import ProductCard from '@/components/basket/ProductCardV2.vue'
import ServicesCard from '@/components/basket/ServicesCard.vue'
import ProductCaption from '@/components/basket/ProductCaption.vue'

export default {

    components: {BasketLayout, AcceptedOffer, ProductCard, ServicesCard, ProductCaption}, 
    mixins: [orderMixin, merchantMixin],
     created() {
      
        this.$tracking.pageView('counter offer refused', 'order', {'Order ID': this.orderId})
    },
    computed: {
        isStatusRefused() {

            return this.isOrderLoaded && this.order.order_status_id == 9 && this.order.refused_counter_offer != undefined ? true : false
        }
    }
}
</script>
<template>
    <basket-layout :withBreadCrumbs="false">
        <div v-if="isStatusRefused">
            <div class="row mt-3 mt-md-5">
                <div class="col-md-7 ms-md-5">
                    <div class="col-md-11 text-light">
                        <h3 class="text-light">Vente annulée</h3>
                        <p>Suite à votre refus de la contre-offre, votre vente est annulée.</p>
                        <p>Afin de recupérer votre produit<sup>(1)</sup>, merci de nous contacter par mail : <a href="mailto:contact@happydealonline.com">contact@happydealonline.com</a>.
                        <p>A bientôt.</p>
                        <a class="btn btn-success" type="button" :href="order.merchant.orders_history_url">Mon Espace Client {{order.merchant.name}}</a>
                        <p><small><sup>(1)</sup> Frais de retour à votre charge</small></p>
                    </div>
                </div>
                <div class="offset-md-0 col-md-4">
                    <div class="card shadow-lg w-100">
                        <product-card :basket="order" />
                        <accepted-offer :counter="order.refused_counter_offer"/>
                    </div>
                    <services-card v-if="merchant" :productType="order.product_type" :merchant="merchant" />
                    <product-caption/>
                </div>
            </div>
        </div>
    </basket-layout>
</template>