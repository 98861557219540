<script type="text/ecmascript-6">

import Media from '../../mixins/media.js'
import Faq from '@/mixins/faq'
import Breadcrumbs from './BreadcrumbsV2.vue'

export default {
    props: {
        withBreadCrumbs: {

            type: Boolean,
            default: true
        }
    },
    components: {Breadcrumbs},
    mixins: [Media, Faq],
    computed: {

        currentStep() {

            return this.$store.getters.currentStep
        },
        homeURL() {

            return process.env.VUE_APP_MAIN_DOMAIN
        }
    },
}
</script>
<template>
    <header class="navbar navbar-expand-lg navbar-light shadow-lg bg-gd-dark">
        <div class="row w-100">
            <div class="col-7 col-md-2 order-1 d-flex align-items-center px-0 ms-md-5">
                <a class="navbar-brand mx-md-0" :href="homeURL">
                    <img class="ms-4 imgjustify-self-end" src="/assets/img/happydeal/logo happy deal.png" />
                </a>
            </div>
            <div class="offset-1 offset-md-0 row mt-5 col-md-8 order-3 order-md-2">
                <div v-if="withBreadCrumbs" class="offset-md-1 col-md-10">
                    <breadcrumbs :currentStep="currentStep" />
                </div>
            </div>
            <div class="col-3 col-md-1 order-2 order-md-2 px-0 d-flex align-items-center">
                <a :href="getFAQURL()" target="_blank" class="link">
                    <i class="bi bi-question-circle mt-1 text-success"></i> <u class="small text-light">Centre d'aide</u>
                </a>
            </div>
        </div>
    </header>
</template>
