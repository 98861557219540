<script type="text/ecmascript-6">

import BasketLayout from '@/layouts/BasketV2.vue'
import orderMixin from '@/mixins/order'
import merchantMixin from '@/mixins/merchant'
import AcceptedOffer from '@/components/basket/AcceptedOfferV2.vue'
import ProductCard from '@/components/basket/ProductCardV2.vue'
import ServicesCard from '@/components/basket/ServicesCard.vue'
import ProductCaption from '@/components/basket/ProductCaption.vue'

export default {

    components: {BasketLayout, AcceptedOffer, ProductCard, ServicesCard, ProductCaption}, 
    mixins: [orderMixin, merchantMixin],
    created() {
      
        this.$tracking.pageView('sent', 'order', {'Order ID': this.orderId})
        this.$store.commit('setCurrentStep', 50)
    },
    methods: {

        getCarrierUrl(trackingNumber) {

            return 'https://www.laposte.fr/outils/suivre-vos-envois?code=' + trackingNumber
        }
    }
}
</script>
<template>
    <basket-layout>
        <div v-if="isOrderLoaded && order.latest_delivery_note">
            <div class="row mt-3 mt-md-5">
                <div class="col-md-7 ms-md-5">
                    <div class="col-md-11 text-light">
                        <h3 class="text-light">Produit expédié</h3>
                        <p>Vous pouvez suivre votre expédition en <a :href="getCarrierUrl(order.latest_delivery_note.tracking_number)" target="_blank">cliquant ici</a>.</p>
                        <p>Nous vous préviendrons par mail dès que votre appareil aura été réceptionné par notre partenaire.</p>
                        <a class="btn btn-success" type="button" :href="order.merchant.orders_history_url">Mon espace client {{order.merchant.name}}</a>
                    </div>
                </div>
                <div class="offset-md-0 col-md-4">
                    <div class="card shadow-lg w-100">
                        <product-card :basket="order" />
                        <accepted-offer :price="order.price" :merchant="order.merchant" :type="order.coupon_type" :bonus="order.brand_campaign"/>
                    </div>
                    <services-card v-if="merchant" :productType="order.product_type" :merchant="merchant" />
                    <product-caption/>
                </div>
            </div>
        </div>
    </basket-layout>
</template>