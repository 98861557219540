import merchantService from '@/services/merchant'

export default {

    computed: {

        merchant() {

            return this.$store.getters.merchant
        },
        merchantId() {

            return this.$store.getters.merchant.id
        }
    },
    methods: {

        async loadMerchant(merchantId) {
            
            if (merchantId == undefined) {

                merchantId = 8451006594
            }
            else {

                if (merchantId != 8451006594) {

                    window.location = process.env.VUE_APP_MAIN_DOMAIN
                }
            }

            if (this.merchant == undefined || this.merchant.id != merchantId) {

                let response = await merchantService.getMerchant(merchantId)    
                let merchant = response.data
                this.$store.commit('setMerchant', merchant)
            }
            
            if (this.merchant != undefined) {

                let subdomain = window.location.hostname.split('.')[0]
                if (subdomain != this.merchant.subdomain) {

                    window.location = window.location.href.replace('://'+subdomain+'.', '://'+this.merchant.subdomain+'.')
                }
            }
        },
        async loadMerchantBySubdomain() {

            if (this.merchant == undefined) {

                let subdomain = window.location.hostname.split('.')[0]
                if (subdomain != 'customer') {

                    let response = await merchantService.getMerchantBySubdomain(subdomain)    
                    let merchant = response.data
                    this.$store.commit('setMerchant', merchant)
                }
            }
        },
    }
}
