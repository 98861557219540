<script type="text/ecmascript-6">

import Faq from '@/mixins/faq.js'

export default {

    mixins: [Faq],
    mounted() {

        let crisp = document.createElement('script');
        let codeCrisp = 'window.$crisp=[];window.CRISP_WEBSITE_ID="'+process.env.VUE_APP_CRISP_WEBSITE_ID+'";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();'
        crisp.appendChild(document.createTextNode(codeCrisp));
        document.body.appendChild(crisp);
        
        let axeptio = document.createElement('script');
        let codeAxeptio = "window.axeptioSettings = {clientId: '"+process.env.VUE_APP_AXEPTIO_CLIENT_ID+"',};(function(d, s) {var t = d.getElementsByTagName(s)[0], e = d.createElement(s);e.async = true; e.src = '//static.axept.io/sdk.js';t.parentNode.insertBefore(e, t);})(document, 'script');"
        axeptio.appendChild(document.createTextNode(codeAxeptio));
        document.body.appendChild(axeptio);
    },
    computed: {

        subdomain() {

            let subdomain = window.location.hostname.split('.')[0]
            if (subdomain == 'customer') {

                subdomain = 'fnac'
            }

            return subdomain
        },
        subdomainUpper() {

            let subdomain = window.location.hostname.split('.')[0]
            if (subdomain == 'customer') {

                subdomain = 'fnac'
            }

            return subdomain.toUpperCase()
        }
    }
}
</script>
<template>
    <footer class="mt-5">
        <div class="container">
            <div class="row pb-2">
                <div class="col">
                    <div class="widget widget-links widget-dark">
                        <ul class="widget-list d-flex flex-wrap justify-content-center">
                            <li class="widget-list-item me-5"><a class="widget-list-link" target="_blank" :href="'/assets/docs/happydealonline_cgu.pdf'">CGU</a></li>
                            <li class="widget-list-item me-5"><a class="widget-list-link" target="_blank" href="/assets/docs/mentions_legales_happydealonline_com.pdf">Mentions légales</a></li>
                            <li class="widget-list-item"><a class="widget-list-link" target="_blank" href="/assets/docs/politique_de_confidentialite_happydealonline.pdf">Politique de confidentialité</a></li>
                        </ul>
                        <ul class="widget-list d-flex flex-wrap justify-content-center">
                            <li class="widget-list-item"><a class="widget-list-link" href='javascript:openAxeptioCookies()'>Modifier vos préférences en matière de cookie</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>
